/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from './UserProvider';
import Translate from '../locale/Translate';
import './RegistrerBruker.scss';
import RegistrerForm from './RegistrerForm';
import RegisteredUserSuccessPage from './RegisteredUserSuccessPage';
import Modal, { ModalSection } from '../common/modal/Modal';
import { useUserActions } from './UserActionProvider';
import OutlineButton from '../common/button/OutlineButton';

const RegistrerBruker = (): JSX.Element => {
  const { toggleCreateUser, createUserOpen } = useUserActions();
  const [userWaitForActivation, setUserWaitForActivation] = useState({ username: '', epostSendt: false });
  const { login } = useContext(UserContext);

  useEffect(() => {
    setUserWaitForActivation({ username: '', epostSendt: false });
  }, [createUserOpen]);

  return (
    <Modal isOpen={createUserOpen} toggle={toggleCreateUser} preventScroll>
      <ModalSection>
        {!userWaitForActivation.username ? (
          <>
            <h1 className="heading-1 my-4">
              <Translate id="registration.pageHeader" />
            </h1>
            <div className="w-full my-3">
              <h2 className="heading-4 tablet:heading-3 pb-2">
                <Translate id="registration.top-info-header" />
              </h2>
              <ul className="list-disc px-4">
                <li className="text-3 tablet:text-2">
                  <Translate id="registration.top-info-1" />
                </li>
                <li className="text-3 tablet:text-2">
                  <Translate id="registration.top-info-2" />
                </li>
                <li className="text-3 tablet:text-2">
                  <Translate id="registration.top-info-3" />
                </li>
              </ul>
            </div>
            {createUserOpen && <RegistrerForm onRegisterSuccess={(user) => setUserWaitForActivation(user)} />}
            <OutlineButton onClick={login} className="w-full text-5 mt-3">
              <Translate id="registration.login-link" />
            </OutlineButton>
          </>
        ) : (
          <RegisteredUserSuccessPage user={userWaitForActivation} />
        )}
      </ModalSection>
    </Modal>
  );
};

export default RegistrerBruker;
